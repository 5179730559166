import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";

import { serviceBlogs } from "../../Utils/Texts/Blogs";
import BlogImage from "../../Components/BlogImage/BlogImage";
import FadeInComponent from "../../Components/FadeInComponent/FadeInComponent";

function Blog() {
  const { id } = useParams();
  const selectedBlogDetails = serviceBlogs?.[id];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box
      sx={{
        padding: { sm: "5rem 15rem", xs: "2rem" },
      }}
    >
      <BlogImage selectedBlogDetails={selectedBlogDetails} />
      <FadeInComponent style={{ width: "70%", margin: "auto" }}>
        <h1>{selectedBlogDetails?.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: selectedBlogDetails?.text }} />
      </FadeInComponent>
    </Box>
  );
}

export default Blog;
