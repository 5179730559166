import { Phone_no } from "./Common";

export const serviceBlogs = {
  hvac: {
    id: "1-havc",
    img: "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2FHVAC3.jpg?alt=media&token=3d1fb59f-c852-45cb-b99b-7be2c1add84a",
    title:
      "Arriva Homes: Keeping Your Home Comfortable All Year Round with Expert HVAC Services",
    text: `
    <p>Your home's heating, ventilation, and air conditioning (HVAC) system is vital for maintaining a comfortable and healthy living environment. But when your HVAC system malfunctions, it can quickly turn your haven into an uncomfortable and even unhealthy space. That's where Arriva Homes comes in – your trusted partner for expert HVAC services in [City, State].
    <br/>
    <br/>
    <h4>Why Choose Arriva Homes for Your HVAC Needs?</h4>
At Arriva Homes, we understand the importance of a well-functioning HVAC system. Our team of certified and experienced technicians is dedicated to providing you with exceptional service, from routine maintenance to emergency repairs. Here's what sets us apart:
    <br/>
    <br/>
<b>Expertise:</b> Our technicians are highly trained and experienced in servicing all makes and models of HVAC systems. They stay up-to-date on the latest industry advancements to ensure they can diagnose and resolve any issue efficiently.
    <br/>
<b>Reliable Service:</b> We understand that HVAC problems can arise at any time. That's why we offer flexible scheduling options, including emergency appointments, to get your system back up and running as soon as possible.
    <br/>
<b>Transparent Communication:</b> We believe in clear and honest communication with our customers. Our technicians will thoroughly explain the problem, discuss your options, and answer any questions you may have before proceeding with any repairs.
    <br/>
<b>Competitive Prices:</b> We offer competitive pricing for all our HVAC services, ensuring you get the best value for your money.
    <br/>
<b>Preventative Maintenance:</b> Regular maintenance is crucial for keeping your HVAC system running smoothly and efficiently. We offer comprehensive preventative maintenance plans to help you avoid costly repairs and extend the lifespan of your system.
    <br/>
    <br/>
<b>Our HVAC Services:</b>
    <br/>
    <br/>
Arriva Homes offers a wide range of HVAC services to meet all your home's needs, including:
    <br/>
    <br/>
<b>Installation:</b> We can help you choose the right HVAC system for your home and handle the entire installation process efficiently.
    <br/>
<b>Repair:</b> Our technicians can diagnose and repair any problem with your heating or cooling system, ensuring your home stays comfortable year-round.
    <br/>
<b>Maintenance:</b> We offer comprehensive preventative maintenance plans that include cleaning, tune-ups, and inspections to keep your system running smoothly and efficiently.
    <br/>
<b>Air Quality Services:</b> We offer air quality testing and solutions to improve the indoor air quality in your home, promoting a healthier environment for you and your family.
    <br/>
    <br/>
Maintaining a Comfortable Home Shouldn't Be a Hassle
    <br/>
    <br/>
Don't let a malfunctioning HVAC system disrupt your comfort at home. Contact Arriva Homes today to schedule an appointment for any of your HVAC needs. Our friendly and professional team is here to help you keep your home comfortable and healthy all year round.
    <br/>
    <br/>
Call us today at ${Phone_no} to learn more about our HVAC services and how we can help you!
    <br/>
<h4>Additional Tips:</h4>
Include high-quality images of technicians working on HVAC systems or satisfied customers enjoying their comfortable homes.
    <br/>
Consider creating a video showcasing the installation, repair, or maintenance process of an HVAC system.
    <br/>
Offer a discount or free consultation to incentivize readers to contact Arriva Homes for their HVAC needs.
    <br/>
Regularly update your blog with informative content related to HVAC systems, common problems, and maintenance tips to establish Arriva Homes as a trusted resource for homeowners.
    <br/>
  </p>`,
  },
  kitchen: {
    id: "2-kitchen",
    img: "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Fkc1.jpg?alt=media&token=0a43fb15-3bb1-4533-a421-210289743dd2",
    title: "Spruce Up Your Kitchen with ARRIVA'S Stunning New Cabinets",
    text: `
    <p>The Heart of Your Home: Kitchen Cabinetry by Arriva Homes
    <br/>
The kitchen is more than just a place to cook; it's the heart of the home, a place where families gather, meals are shared, and memories are made. At Arriva Homes, we understand the importance of creating a kitchen that not only looks stunning but also functions flawlessly. That's why we offer exceptional kitchen cabinet services, designed to elevate your space and meet your unique needs.
    <br/>
    <br/>
<b>Crafting Dream Kitchens with Bespoke Cabinetry</b>
    <br/>
    <br/>
We believe that your kitchen cabinets should be as unique as your home. Our team of experienced designers will work closely with you to understand your vision, lifestyle, and storage requirements. From modern and sleek to timeless and traditional, we offer a wide range of cabinet styles, finishes, and materials to bring your dream kitchen to life.
    <br/>
    <br/>
<b>Benefits of Choosing Arriva Homes for Your Kitchen Cabinets:</b>
    <br/>
    <br/>
<b>Unmatched Quality:</b> We use only the finest materials and construction techniques to ensure your cabinets are built to last.
    <br/>
<b>Expert Design:</b> Our designers have a keen eye for detail and will help you create a kitchen that is both stylish and functional.
    <br/>
<b>Seamless Installation:</b> Our experienced installation team will ensure your cabinets are installed flawlessly, minimizing disruption to your home.
    <br/>
<b>Exceptional Customer Service:</b> We are committed to providing you with exceptional customer service throughout the entire process.
    <br/>
<b>More Than Just Cabinets:</b> A Complete Kitchen Transformation
    <br/>
    <br/>
At Arriva Homes, we offer a comprehensive range of kitchen renovation services,  extending beyond just cabinets. We can help you with everything from countertops and backsplashes to flooring and lighting, ensuring a cohesive and stylish look for your entire kitchen.
    <br/>
    <br/>
    <b>Ready to Start Your Kitchen Cabinet Project?</b>
    <br/>
    <br/>
If you're dreaming of a kitchen that is both beautiful and functional, contact Arriva Homes today. We offer free consultations to discuss your project and answer any questions you may have. Let us help you create the kitchen of your dreams, a space that will be the heart of your home for years to come.
    <br/>
Don't let a malfunctioning HVAC system disrupt your comfort at home. Contact Arriva Homes today to schedule an appointment for any of your HVAC needs. Our friendly and professional team is here to help you keep your home comfortable and healthy all year round.
    <br/>
    <br/>
Call us today at ${Phone_no} to learn more about our HVAC services and how we can help you!
    <br/>
    <br/>
    <b>Additional Tips:</b>
    <br/>
    <br/>
Include high-quality images of technicians working on HVAC systems or satisfied customers enjoying their comfortable homes.
    <br/>
Consider creating a video showcasing the installation, repair, or maintenance process of an HVAC system.
    <br/>
Offer a discount or free consultation to incentivize readers to contact Arriva Homes for their HVAC needs.
    <br/>
Regularly update your blog with informative content related to HVAC systems, common problems, and maintenance tips to establish Arriva Homes as a trusted resource for homeowners.
    <br/>
  </p>`,
  },
  plumbing: {
    id: "3-plumbing",
    img: "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Fplumbing1.jpg?alt=media&token=eef70726-add4-4f93-bab2-c2f9df859afb",
    title:
      "Keep Your Home Flowing Smoothly with Arriva Homes Plumbing Services",
    text: `
    <p>Having a functional plumbing system is essential for a comfortable and healthy home. At Arriva Homes, we understand that plumbing problems can be stressful and inconvenient. That's why we offer a wide range of plumbing services to keep your home's water system running smoothly.
    <br/>
    <br/>
    <b>Our Services:</b>
    <br/>
    <br/>
    <b>Leak Repair:</b> We can identify and repair leaks, both big and small, to prevent water damage and save you money on your water bill.
    <br/>
    <b>Fixture Installation and Repair:</b> From faucets and toilets to showerheads and garbage disposals, our experts can install and repair all your plumbing fixtures.
    <br/>
    <b>Drain Cleaning:</b> Clogged drains are a common household issue. We have the tools and expertise to clear clogged drains quickly and efficiently.
    <br/>
    <b>Water Heater Services:</b> Whether you need a new water heater installation, repair, or maintenance, we can help ensure you have hot water when you need it.
    <br/>
    <b>Repiping:</b> If your home's plumbing is outdated or damaged, we can provide complete repiping services.
    <br/>
    <b>Emergency Plumbing:</b> We understand that plumbing emergencies can happen at any time. That's why we offer 24/7 emergency plumbing services to get your home back up and running as soon as possible.
    <br/>
    <br/>
    <b>Why Choose Arriva Homes Plumbing Services?</b>
    <br/>
    <br/>
    <b>Experienced and Licensed Plumbers:</b> Our team of plumbers is highly trained, experienced, and licensed. We can handle any plumbing problem you may have.
    <br/>
    <b>Fast and Reliable Service:</b> We understand that plumbing problems can't wait. We offer prompt and reliable service to get your home back to normal as quickly as possible.
    <br/>
    <b>Competitive Rates:</b> We offer competitive rates for all of our plumbing services.
    <br/>
    <b>Satisfaction Guaranteed:</b> We are committed to your satisfaction. We guarantee the quality of our work and offer a warranty on all of our services.
    <br/>
    <br/>
Don't let plumbing problems disrupt your life. Contact Arriva Homes Plumbing Services today for a free consultation!
    <br/>
Call us at ${Phone_no} to learn more about our services.
    <br/>
    <br/>
We look forward to helping you keep your home's plumbing system functioning smoothly!
  </p>`,
  },
  electrical: {
    id: "4-electrical",
    img: "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Felectrical1.jpg?alt=media&token=c13717e9-6480-4b7f-a4df-92395798b8ff",
    title: "Arriva Homes: Illuminating Your Dreams and Powering Your Comfort",
    text: `
    <p>At Arriva Homes, we understand the importance of a safe and functional electrical system in your dream home. Our team of certified electricians is dedicated to providing top-notch electrical services to ensure your home is illuminated beautifully, powered reliably, and meets all safety standards.
    <br/>
    <br/>
<b>Why Choose Arriva Homes for Your Electrical Needs?</b>
    <br/>
    <br/>
<b>Safety First:</b> Our priority is your safety. Our electricians are extensively trained and licensed to handle all your electrical needs according to code.
    <br/>
<b>Experience You Can Trust:</b> With years of experience in residential electrical services, we have the expertise to tackle any project, big or small.
    <br/>
<b>Quality Workmanship:</b> We take pride in our meticulous attention to detail and ensure every installation and repair is completed to the highest standards.
    <br/>
<b>Comprehensive Services:</b> We offer a wide range of electrical services to meet all your needs, from lighting installations and repairs to electrical panel upgrades and rewiring.
    <br/>
<b>Customer Satisfaction Guaranteed:</b> We are committed to your satisfaction and strive to provide excellent customer service throughout the entire process.
    <br/>
    <br/>
<b>Our Electrical Services Include:</b>
    <br/>
    <br/>
<b>Lighting Installations and Repairs:</b> We can install new light fixtures, troubleshoot and repair existing ones, and upgrade your lighting system to enhance the ambience and functionality of your home.
    <br/>
<b>Electrical Panel Upgrades:</b> If your electrical panel is outdated or overloaded, we can safely upgrade it to meet your current and future electrical needs.
    <br/>
<b>Power Outlet Installations and Repairs:</b> Need additional outlets? We can install them wherever you need them most. We can also repair faulty outlets to ensure safety and functionality.
    <br/>
<b>Ceiling Fan Installations:</b> Add comfort and style to your home with ceiling fan installations.
    <br/>
<b>Smoke Detector and Carbon Monoxide Detector Installations:</b> Ensure the safety of your family with the installation of smoke detectors and carbon monoxide detectors.
    <br/>
<b>Home Theater Wiring:</b> We can set up your home theater system for an immersive and enjoyable entertainment experience.
    <br/>
<b>Troubleshooting and Repairs:</b> Experiencing electrical problems? We can diagnose the issue and fix it efficiently.
    <br/>
<b>Security System Wiring:</b> We can install the wiring for your security system to keep your home safe and secure.
    <br/>
    <br/>
<b>Arriva Homes: Lighting Up Your Life, One Service at a Time</b>
    <br/>
    <br/>
Contact Arriva Homes today at ${Phone_no} to schedule a consultation and discuss your electrical needs. We offer competitive rates, free estimates, and exceptional service. Let us help you create a safe, comfortable, and well-lit environment for your dream home.
  </p>`,
  },
  painting: {
    id: "5-painting",
    img: "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Fpainting1.jpg?alt=media&token=c9f521ea-5fd0-46d6-9109-a1d1ce3dfbd4",
    title:
      "Breathe New Life into Your Home with Arriva Homes Painting Services",
    text: `
    <p>Does your home's interior look dull and outdated? Is the exterior paint peeling and faded? A fresh coat of paint can do wonders for the aesthetics and value of your property. At Arriva Homes, we offer exceptional painting services in Tamil Nadu, India, to bring your vision to life.
    <br/>
    <br/>
<b>Why Choose Arriva Homes for Your Painting Needs?</b>
    <br/>
    <br/>
<b>Experienced Professionals:</b> Our team of skilled and experienced painters is dedicated to providing top-notch service. We use only high-quality paints and meticulous attention to detail to ensure a flawless finish.
    <br/>
<b>Wide Range of Services:</b> Whether you need a complete interior or exterior repaint, or just a touch-up for a specific room, we can handle it all. We also offer services like power washing, drywall repair, and color consultation.
    <br/>
<b>Unwavering Commitment to Quality:</b> We use only premium paints and primers that are resistant to fading, chipping, and mildew. Our meticulous preparation process ensures long-lasting results you'll love.
    <br/>
<b>Affordability:</b> We offer competitive pricing and flexible financing options to fit your budget. We'll work with you to create a customized plan that meets your needs.
    <br/>
<b>Exceptional Customer Service:</b> At Arriva Homes, we prioritize customer satisfaction. We take the time to understand your vision, answer your questions, and keep you informed throughout the entire project.
    <br/>
    <br/>
<b>Transform Your Space with the Perfect Color Palette</b>
    <br/>
    <br/>
A fresh coat of paint can completely transform the look and feel of your home. Our experienced color consultants can help you choose the perfect colors to complement your style and create the desired ambiance.
    <br/>
    <br/>
Breathe new life into your living room with a warm and inviting color scheme. Create a tranquil oasis in your bedroom with calming blues and greens. Make a bold statement with a vibrant accent wall.
    <br/>
    <br/>
Schedule Your Free Consultation Today! Ready to give your home the makeover it deserves? Contact Arriva Homes today to schedule a free consultation. We'll discuss your project in detail, provide you with a free estimate, and answer any questions you may have.
    <br/>
    <br/>
Don't wait any longer to create the home of your dreams. Contact Arriva Homes today at ${Phone_no} !
  </p>`,
  },
};
