import { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";

import { APP_NAME, NavItems } from "../../Utils/Texts/Common";

function Header(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const scrollTo = (itemId)=>{
    const element = document.getElementById(itemId);
    if(element){
      element.scrollIntoView({behavior:'smooth'});
    }
  }

  const NavigateTo = (item) =>{
    if(item.type === 'scroll'){
      navigate("/")
      scrollTo(item.link)
    }else{
      navigate(item.link)
    }
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography onClick={() => navigate("/")} variant="h6" sx={{ my: 2 }}>
        {APP_NAME}
      </Typography>
      <Divider />
      <List>
        {NavItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton
              onClick={() =>  NavigateTo(item)}
              className="text-capitalize"
              sx={{ textAlign: "center" }}
            >
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        sx={{
          backgroundColor: "black",
          opacity: { xs: 0.95, sm: 1 },
          py: { xs: 0, sm: 1 },
        }}
        component="nav"
        position="fixed"
      >
        <Toolbar>
          <Typography
            onClick={() => navigate("/")}
            component="div"
            className="text-uppercase cursor-pointer"
            sx={{
              flexGrow: 1,
              letterSpacing: "5px",
              fontSize: { xs: '16px', sm: '26px' },
              mx: { xs: "auto", sm: "2px" },
              fontFamily:'ivypresto-display'
            }}
          >
            {APP_NAME}
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mx:'auto',
              display: { sm: "none" },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {NavItems.map((item, index) => (
              <Button
                onClick={() => NavigateTo(item)}
                key={`${item.label}-${index}`}
                sx={{ color: "#fff" }}
              >
                {item.label}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "70%",
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

export default Header;
