import { Box, Typography } from "@mui/material";
import React from "react";
import LocationCityRoundedIcon from '@mui/icons-material/LocationCityRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from "react-router-dom";

import { APP_NAME, Address, Email_Id, Phone_no } from "../../Utils/Texts/Common";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        backgroundColor: "black",
        color: "white",
        minHeight: "10vh",
        p: "2rem",
        fontSize: '14px'
      }}
    >
    <Typography
      onClick={() => navigate("/")}
      variant="h6"
      component="div"
      className="text-uppercase cursor-pointer"
      sx={{
        flexGrow: 1,
        letterSpacing: "5px",
        mx: { xs: "auto", sm: "2px" },
        fontFamily:'ivypresto-display'
      }}
    >
      {APP_NAME}
    </Typography>
      <Box className="text-center text-capitalize"
      >
        <div>
          <p className="d-flex-align-center">
            <LocalPhoneRoundedIcon sx={{ m: "5px", width: '20px', height: '20px' }} />
            Mobile no : <a className='normal-text-link' href={`tel:+${Phone_no}`}> {Phone_no}</a>
          </p>
          <p className="d-flex-align-center">
            <EmailIcon sx={{ m: "5px", width: '20px', height: '20px' }} />
            Email : <a className='normal-text-link text-lowercase' href={`mailto::${Email_Id}`}>{Email_Id}</a>
          </p>
          <p className="d-flex-align-center">
            <LocationCityRoundedIcon sx={{ m: "5px", width: '20px', height: '20px' }} />
            Location : {Address}
          </p>
        </div>
        <div>
          &#169; {new Date()?.getFullYear()} {APP_NAME}, All rights reserved
        </div>
      </Box>
    </Box>
  );
};

export default Footer;
