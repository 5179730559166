import React, { useState } from "react";
import { Alert, Box, Button, Snackbar, TextField } from "@mui/material";
import emailjs from "@emailjs/browser";

import FadeInComponent from '../FadeInComponent/FadeInComponent'
import TextHeadings from "../TextHeadings/TextHeadings";
import "./contact.css";

const ContactUs = () => {
  const [contactUsFormData, setcontactUsFormData] = useState({
    userName: "",
    email: "",
    msg: "",
  });
  const [error, seterror] = useState("");
  const [open, setOpen] = useState(false);
  const [toasterMessage, setToasterMessage] = useState({
    status: '',
    message: ''
  });

  // Have to set limit for sending email to avaid spaming
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { userName, email, msg } = contactUsFormData;

    emailjs
      .send(
        "service_9wk0gmm",
        "template_l8nr1y9",
        {
          from_name: userName,
          message: msg,
          reply_to: email,
        },
        "0KZ87jwslyrl0T7ho"
      )
      .then(
        (response) => {
          setOpen(true);
          setToasterMessage({
            status: 'success',
            message: "Your message sent successfully.",
          });
          console.log("SUCCESS!", response.status, response.text);
        },
        (err) => {
          setOpen(true);
          setToasterMessage({
            status: 'error',
            message: "Sorry, There was some issue with sending your message.",
          });
          console.log("FAILED...", err);
        }
      );
  };

  const onInputChange = (e) => {
    if (e.target.name === "email") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(e.target.value)) {
        seterror("Please Enter a Valid email");
      } else seterror("");
    }
    setcontactUsFormData({
      ...contactUsFormData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div id="contact-us" className="contact-block">
      <div className="underline-orange">
        <TextHeadings heading={"Contact us"} />
      </div>
      <FadeInComponent>
        <Box
          component="form"
          className="contact-form background-white"
          onSubmit={handleSubmit}
        >
          <div className="form-cont">
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={() => setOpen(false)}
            >
              <Alert severity={toasterMessage?.status}>{toasterMessage.message}</Alert>
            </Snackbar>
            <div className="input-margin">
              <TextField
                required
                id="outlined-required"
                label="Your Name"
                className="inputField"
                name="userName"
                value={contactUsFormData?.userName}
                onChange={onInputChange}
              />
            </div>
            <div className="input-margin">
              <TextField
                required
                error={false}
                id="outlined-required"
                className="inputField"
                label="Email"
                type="email"
                name="email"
                value={contactUsFormData?.email}
                onChange={onInputChange}
              />
            </div>
            <div className="input-margin">
              <textarea
                className="textArea"
                minLength={10}
                placeholder="Enter your Message"
                name="msg"
                value={contactUsFormData?.msg}
                onChange={onInputChange}
              />
            </div>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <Button type="submit" className="w-100" variant="contained">
              Send
            </Button>
          </div>
          <iframe
            title="Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1325285.5925661253!2d-124.27443069168842!3d49.55156159353423!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x546349f7c0345ac9%3A0xfec1d62f913e858e!2sFraser%20Valley%2C%20BC%2C%20Canada!5e0!3m2!1sen!2sin!4v1702727339103!5m2!1sen!2sin"
            style={{ border: 0 }}
            allowFullScreen=""
            className={"location-map"}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Box>
      </FadeInComponent>
    </div>
  );
};

export default ContactUs;
