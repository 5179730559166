import { Box } from "@mui/material";

import ServicesCard from "../Cards/ServicesCard/ServicesCard";
import FadeInComponent from "../FadeInComponent/FadeInComponent";
import TextHeadings from "../TextHeadings/TextHeadings";
import { TAB_DATA } from "../../Utils/Texts/Common";

const Services = () => {
  return (
    <Box
      className="text-center"
      id={'ourservices'}
      sx={{
        display: "flex",
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        margin: "1rem",
        p: 3,
        my: 3,
        width: "95%",
        margin: 'auto',
      }}
    >
        {TAB_DATA.map((data) => (
          <FadeInComponent>
            <ServicesCard {...data} key={data?.id} />
          </FadeInComponent>
        ))}
    </Box>
  );
};

export default Services;
