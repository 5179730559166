import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { About, Home, Portfolio, Blog } from "./pages";
import PageNotFound from "./Components/PageNotFound/PageNotFound";
import Layout from "./Layout";
import Careers from "./pages/Careers/Careers";

const AppRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" exact element={<Layout />}>
      <Route path="" element={<Home />} />
      <Route path="careers" element={<Careers />} />
      <Route path="about" element={<About />} />
      <Route path="portfolio" element={<Portfolio />} />
      <Route path="blog/:id" element={<Blog />} />

      <Route path="*" element={<PageNotFound />} />
    </Route>
  )
);

export default AppRouter;
