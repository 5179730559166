import Banner from "../../Components/Banner/Banner";
import HereFromUs from "../../Components/HereFromUs/HereFromUs";
import ImageWithText from "../../Components/ImageWithText/ImageWithText";
import Advantages from "../../Components/Advantages/Advantages";
import ContactUs from "../../Components/Contactus/ContactUs";
import { QUALITY } from "../../Utils/Texts/Common";
import Services from "../../Components/Services/Services";
import ServiceTypeBanners from "../../Components/ServiceTypeBanner/ServiceTypeBanners";

const Home = () => {
  const house = 'https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Fhome.jpg?alt=media&token=de2424ec-e650-41b6-85ca-5583f5a08f1c';
  return (
    <div>
      <Banner />
      <ServiceTypeBanners/>
      <Advantages />
      <ImageWithText
        containerHeading={"A LifeTime of"}
        containerHeadingSubText={"quality"}
        typo={QUALITY}
        imageDirection={"right"}
        imageSrc={house}
      />
      <HereFromUs />
      <ContactUs/>
    </div>
  );
};

export default Home;
