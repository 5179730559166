import React from "react";
import { Box } from "@mui/material";

import AdvantageCard from "../Cards/AdvantageCard/AdvantageCard";
import { AdvantagesContent } from "../../Utils/Texts/Home";
import FadeInComponent from '../FadeInComponent/FadeInComponent'
import TextHeadings from "../TextHeadings/TextHeadings";

const Advantages = () => {
  return (
    <Box
      id="advantage"
      className="text-center"
      sx={{
        p: 5,
        my: 5,
        backgroundColor: "#fbfbfb",
      }}
    >
      <TextHeadings heading={"The Arriva Homes"} subText={"Advantages"} />
      <FadeInComponent>
        <Box sx={{
          display: {
            sm: 'grid',
            xs: 'grid'
          },
          margin: 'auto',
          gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 3fr)' },
        }}>
          {AdvantagesContent.map((advantage, index) => {
            return (
              <AdvantageCard
                key={`${advantage.title}-${index}`}
                advantage={advantage}
              />
            );
          })}
        </Box>
      </FadeInComponent>
    </Box>
  );
};

export default Advantages;
