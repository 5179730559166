import { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import "./style.css";
import { useNavigate } from "react-router-dom";

const ServicesCard = ({ id, heading, imageSrc, text,  navlink }) => {
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  return (
    <Card
      key={`${id}-${heading}`}
      sx={{ 
        maxWidth: { md: "35dvw" }, 
        margin: "1rem auto", 
        maxHeight: "455px",
      }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height="250"
          image={imageSrc[0]}
          alt={`${heading} service`}
        />
        <CardContent>
          <Typography textAlign={'left'} gutterBottom variant="h5" component="div">
            {heading}
          </Typography>
          <Typography textAlign={'left'} onClick={() => setShowMore(!showMore)} variant="body2" component="div" color="text.secondary">
            {showMore ? text : text.substring(0, 125) + "......"}
            <Typography
              variant="span"
              color={"primary.main"}
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? "show less" : "show more"}
            </Typography>
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions onClick={() => navigate(`blog/${navlink}`)}>
        <Button className="view-more-btn" size="small" color="primary">
          View More <ArrowRightAltIcon className="icon" />
        </Button>
      </CardActions>
    </Card>
  );
};

export default ServicesCard;
