import React, { useRef } from "react";

import FadeInComponent from '../FadeInComponent/FadeInComponent'
import "./style.css";

function Banner() {
  const banner = useRef(null);
  return (
    <div className="banner">
      <video className="background-video" poster="https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Fskyscraper-background.jpeg?alt=media&token=ce1af830-572f-4655-b63d-99a9bd1b683e" autoPlay playsInline muted loop>
        <source src="https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Fvideos%2Fskyscrapers-latest.mp4?alt=media&token=e62fd36e-db46-4f80-8565-c8ad66f19fce" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div ref={banner} className={"banner-body-content-container"}>
        <FadeInComponent>
          <div className={"font-big"}>BUILDING YOUR DREAM WITH VISION, INNOVATION AND PRIDE</div>
        </FadeInComponent>
      </div>
    </div>
  );
}

export default Banner;
