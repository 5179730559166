import React from "react";
import "./style.css";
function TextHeadings({ heading, subText, islight = false }) {
  return (
    <div>
      <div className={`text-typo-heading-1 ${islight && "lightClr"}`}>
        {heading}
      </div>
      <div className={`text-typo-sub-text ${islight && "lightClr"}`}>
        {subText}
      </div>
    </div>
  );
}

export default TextHeadings;
