import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'

import { CareersContent } from '../../Utils/Texts/Careers'
import FadeInComponent from '../../Components/FadeInComponent/FadeInComponent'
import CareersForm from './Form'
import { Email_Id } from '../../Utils/Texts/Common'

const Careers = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Box
        sx={{
          width: {
            xs: '100%',
            md: '75%'
          },
          textAlign: 'left',
          margin: 'auto',
          padding: { xs: '2rem' },
          paddingTop: { xs: '4rem', sm: '6rem' }
        }}>
        <FadeInComponent>
          <Context
            title={CareersContent.career.title}
            content={CareersContent.career.text}
          />
        </FadeInComponent>
        <FadeInComponent>
          <Context
            title={CareersContent.ourCulture.title}
            content={CareersContent.ourCulture.text}
          />
        </FadeInComponent>
        <FadeInComponent>
          <Typography
            variant="h6"
            sx={{
              marginBottom: '1rem'
            }}
          >
            Send your resume to 
            <a className='normal-text-link text-lowercase' href={`mailto::${Email_Id}`}> {Email_Id} </a>
            or submit the below form we'll get in touch with you
          </Typography>
          <CareersForm/>
        </FadeInComponent>
      </Box>
    </div >
  )
}

export default Careers

const Context = ({ title, content }) => {
  return (<Box>
    <Typography
      variant="h3"
      sx={{
        marginBottom: '1rem'
      }}
    >
      {title}
    </Typography>
    <Typography
      variant="body1"
      sx={{
        marginBottom: '1rem'
      }}
    >
      {content}
    </Typography>
  </Box>)
}