export const APP_NAME = "Arriva Homes Ltd";

export const NavItems = [
  {
    label: "About",
    link: "advantage",
    type: 'scroll'
  },
  {
    label: "our services",
    link: "ourservices",
    type: 'scroll'
  },
  // {
  //   label: "Portfolio",
  //   link: "",
  // },
  {
    label: "Careers",
    link: "/careers",
  },
  {
    label: "Contact us",
    link: "contact-us",
    type: 'scroll'
  },
];

export const Address =
  "lower mainland | fraser valley | vancover island | sunshine coast | canada";

export const Phone_no = "+1-604-399-9991";

export const Email_Id = "arrivahomes@gmail.com";

export const Founder = "Ankush Garg";

export const USER_COMMENTS = [
  {
    comment:
      "Arriva constructs residences with exceptional craftsmanship and unparalleled quality. Their adept and amiable staff guarantees a seamless process, thereby elevating the overall experience to a level of distinction.",
    user_name: "Ryan & Emily Mitchell",
  },
  {
    comment:
      "Arriva constructs top-notch homes and employs exceptional individuals. Their team facilitates a seamless process, making it effortlessly easy for clients.",
    user_name: "Kyle & Lauren MacKenzie",
  },
  {
    comment:
      "Arriva constructed our home, and we are absolutely delighted with it. Throughout the entire process, the Ankush's team demonstrated collaboration and professionalism. We extend our gratitude for providing us with such a fantastic experience.",
    user_name: "Aryan & Priya",
  },
  {
    comment:
      "Outstanding service from your HVAC team! The work in our home was excellent, and we value the professionalism and efficiency displayed throughout. Thank you for a job well done!",
    user_name: "Tyler & Chelsea Thompson",
  },
];

export const QUALITY =
  "Arriva home is more than just the materials from which it is built; it’s a space where life happens, where first steps are taken, where laughter echoes through the hallways, and where a lifetime of memories are made.";

const tab_images = {
  HAVC: [
    "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2FHVAC3.jpg?alt=media&token=3d1fb59f-c852-45cb-b99b-7be2c1add84a",
    "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2FHVAC_img1.jpg?alt=media&token=16e61c44-5be4-44e0-8c7b-50a1027d7683",
    "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2FHVAC2.jpeg?alt=media&token=9ef9994c-e01b-4f50-aea0-b9137f668724",
    "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2FHVAC4.jpeg?alt=media&token=af2c3009-b9ac-40a4-ac8b-14bf0ece8efc",
  ],
  Kitchen: [
    "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Fkc1.jpg?alt=media&token=0a43fb15-3bb1-4533-a421-210289743dd2",
    "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Fkc2.png?alt=media&token=5b1bcf0c-3e3f-4ebe-b51c-508401536106",
    "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Fkc3.png?alt=media&token=43824259-776d-4b0d-b57c-0e13ad010717",
    "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Fkc4.jpg?alt=media&token=4e3a8cb0-f39c-4e28-98c0-b3d22e900a6a",
  ],
  Plumbing: [
    "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Fplumbing1.jpg?alt=media&token=eef70726-add4-4f93-bab2-c2f9df859afb",
    "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Fplumbing2.jpg?alt=media&token=1b090bca-adbc-45a5-9e4d-eb69e32e8134",
    "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Fpainting3.jpg?alt=media&token=b8e9a118-1762-42f6-be6c-2ddbb14a1e07",
    "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Fpainting4.jpg?alt=media&token=a7ca3203-7ea8-4e71-a669-6c457d516be9",
  ],
  Electrical: [
    "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Felectrical1.jpg?alt=media&token=c13717e9-6480-4b7f-a4df-92395798b8ff",
    "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Felectrical2.jpg?alt=media&token=4a410780-021c-4682-9866-a840bfdc50df",
    "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Felectrical3.jpg?alt=media&token=5ecd60b8-2c8d-4851-9ca6-b8fac04a6b16",
    "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Felectrical4.gif?alt=media&token=8e466fef-68ea-40f2-83f7-4dc12e591e24",
  ],
  Painting: [
    "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Fpainting1.jpg?alt=media&token=c9f521ea-5fd0-46d6-9109-a1d1ce3dfbd4",
    "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Fpainting2.avif?alt=media&token=1c5d1947-209b-42b8-ae73-d34b538577ee",
    "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Fpainting3.jpg?alt=media&token=b8e9a118-1762-42f6-be6c-2ddbb14a1e07",
    "https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Fpainting4.jpg?alt=media&token=a7ca3203-7ea8-4e71-a669-6c457d516be9",
  ],
};
export const TAB_DATA = [
  {
    id: 1,
    heading: "HVAC",
    imageSrc: tab_images.HAVC,
    navlink:'hvac',
    text: "With over a decade of experience, ARRIVA provides comprehensive HVAC system installation services tailored to your specific needs. Our HVAC solutions cover a spectrum from mid-sized units to large-scale complexes, catering to domestic, industrial, and commercial projects. Our services include a thorough pre-installation survey of the designated area, installation carried out by our experienced and certified technicians, and a post-installation quality inspection. Leveraging the expertise of our qualified engineers, we offer complete system upgrades to efficiently cool your space.",
  },
  {
    id: 2,
    heading: "Kitchen Cabinets",
    imageSrc: tab_images.Kitchen,
    navlink:'kitchen',
    text: "After dedicating a decade to honing my expertise in crafting exquisite kitchen designs, refining the perfect palette of cabinet colors, and meticulously planning every nuanced detail that elevates a kitchen space, I understand that entrusting such a project requires a seasoned professional. Whether you're embarking on a kitchen remodel or constructing an entirely new culinary haven, Christopher Scott Cabinetry stands as the trusted partner, bringing a wealth of 10 years' experience to guide you from inception to completion. We are confident in our ability to transform your envisioned kitchen into a reality. Specializing in fully custom inset cabinetry, our team excels in crafting timeless and bespoke pieces tailored to your unique style and functional requirements.",
  },
  {
    id: 3,
    heading: "Plumbing",
    imageSrc: tab_images.Plumbing,
    navlink:'plumbing',
    text: "While you may be able to fix that leaky pipe underneath the sink in your kitchen or unclog the shower drain, the complex plumbing systems that run through commercial properties are best left to the professionals. Your company could take a large financial hit due to lengthy downtimes associated with plumbing issues. We understand that emergencies often occur when we least expect them, your business deserves high-quality, prompt, and expert plumbing services. When you choose AAFM services you can rest assured that your plumbing issues will be repaired properly. We do plumbing installation and repair.",
  },
  {
    id: 4,
    heading: "Electrical",
    imageSrc: tab_images.Electrical,
    navlink:'electrical',
    text: "Our certified electricians are committed to excellence in every project. We prioritize clear communication, adherence to industry standards, and a strong focus on safety. With us, you can trust that your electrical needs will be met with precision and care. Your satisfaction is our top priority, and we strive to deliver results that reflect the highest level of professionalism in the electrical trade.Our commitment to swift response and resolution sets us apart. When you reach out to us, you can expect efficient, reliable solutions delivered on the same day. Your satisfaction is our priority, and we aim to provide timely assistance for all your electrical requirements.",
  },
  {
    id: 5,
    heading: "Painting",
    imageSrc: tab_images.Painting,
    navlink:'painting',
    text: "ARRIVA helps you transform your home with color. If it’s a complete house repaint, an eye-catching accent wall, or anything in between, we will handle it. Our local and professional house painters can paint houses and apartments of all shapes and sizes, interior and exterior.Trade Partner Program offers exclusive perks and services to its members, including access to experienced house painters and wallpaper installers across the country. It’s efficient, easy to use, and equipped to service clients in 11 markets nationwide. Our remote access enables designers to quickly service clients in cities beyond their hometowns.",
  },
];
