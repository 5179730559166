import React from 'react'
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';

import ImageWithText from '../ImageWithText/ImageWithText'
import FadeInComponent from '../FadeInComponent/FadeInComponent';
import TextHeadings from '../TextHeadings/TextHeadings';
import Services from '../Services/Services';
import { servicesContent } from '../../Utils/Texts/Home';

const ServiceTypeBanners = () => {
    return (
        <div className='text-center' style={{
            overflow: 'hidden',
            margin: '1rem auto',
        }} >
            <TextHeadings heading={"we are an expertise providing services in"} />
            <FadeInComponent>
                <ImageWithText
                    containerHeadingSubText={servicesContent["residential"].title}
                    typo={servicesContent["residential"].text}
                    imageDirection={"left"}
                    imageSrc={servicesContent["residential"].image}
                    floatImage={true}
                />
            </FadeInComponent>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: { xs: 'column', md: 'row' },
                    width: {
                        xs: '100%',
                        md: '78%'
                    },
                    margin: 'auto',
                    padding: '2rem 0'
                }}
            >
                <FadeInComponent>
                    <ImageCard src={servicesContent["commercial"].image}
                        title={servicesContent["commercial"].title}
                        text={servicesContent["commercial"].text} />
                </FadeInComponent>
                <FadeInComponent>
                    <ImageCard src={servicesContent["retail"].image}
                        title={servicesContent["retail"].title}
                        text={servicesContent["retail"].text} />
                </FadeInComponent>
            </Box>
            <Services />
        </div>
    )
}

export default ServiceTypeBanners

export const ImageCard = ({ src, title, text }) => {
    return (
        <Card sx={{
            maxWidth: {
                xs: '90%',
                md: '35dvw',
            },
            maxHeight: 'max-content',
            margin: '1rem auto'
        }}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="400"
                    image={src}
                    alt={title}
                />
                <CardContent>
                    <Typography gutterBottom variant="h4" component="div" textAlign={'left'}>
                        {title}
                    </Typography>
                    <Typography variant="body1" className='quality-typo-life-time-quality' color="text.secondary" textAlign={'left'}>
                        {text}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}