import React from "react";
import {
  CardActions, Card,
  CardContent
} from '@mui/material';

import "./style.css";
import { ReactComponent as QuoteIcon } from "../../Utils/Images/quotes.svg";
import TextHeadings from "../TextHeadings/TextHeadings";
import { USER_COMMENTS } from "../../Utils/Texts/Common";
import FadeInComponent from "../FadeInComponent/FadeInComponent";

function HereFromUs() {
  return (
    <div className={"hero-comments-container"}>
      <div className="text-center">
        <TextHeadings heading={"hear from our"} subText={"Moving Fans"} />
      </div>
      <FadeInComponent>
        <div className="comments-container">
          {USER_COMMENTS.map((review, index) => (
            <div key={index} className="here-from-us-main-content">
              <QuoteIcon className="icon" />
              <section className="font-comments">{review?.comment}</section>
              <div>{review?.user_name}</div>
            </div>
          ))}
        </div>
      </FadeInComponent>
    </div>
  );
}

export default HereFromUs;