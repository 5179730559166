import React from "react";
import "./style.css";
import FadeInComponent from "../FadeInComponent/FadeInComponent";

function BlogImage({ selectedBlogDetails }) {
  return (
    <FadeInComponent>
      <img
        src={selectedBlogDetails?.img}
        alt={selectedBlogDetails?.id}
        loading="lazy"
        className="center-image-blog"
      />
    </FadeInComponent>
  );
}

export default BlogImage;
