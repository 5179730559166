import React from "react";
import { Box } from "@mui/material";

import FadeInComponent from '../FadeInComponent/FadeInComponent'
import TextHeadings from "../TextHeadings/TextHeadings"
import "./style.css";

function ImageWithText({
  containerHeading,
  containerHeadingSubText,
  typo,
  imageSrc,
  imageDirection,
  floatImage = false
}) {
  return (
    <FadeInComponent>
      <div className={"hero-container-life-time-quality"}>
        {imageDirection === "left" && (
          <Image
            src={imageSrc}
            alt={containerHeadingSubText}
            floating={floatImage}
          />
        )}
        <section
          className={"typo-container-life-time-quality d-flex-just-align-end"}
        >
          <TextHeadings
            heading={containerHeading}
            subText={containerHeadingSubText}
          />
          <aside className={"quality-typo-life-time-quality"}>{typo}</aside>
        </section>
        {imageDirection === "right" && (
          <Image
            src={imageSrc}
            alt={containerHeadingSubText}
            floating={floatImage}
          />
        )}
      </div>
    </FadeInComponent>
  );
}

export default ImageWithText;

export const Image = ({ src, alt, floating }) => {
  return (
    <Box className={`image-block ${floating && 'floater-animation'}`}>
      <img
        src={src}
        alt={alt}
        loading="lazy"
      />
    </Box>
  )
}
