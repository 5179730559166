import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import React from "react";

const AdvantageCard = ({ advantage }) => {
  return (
    <Card
      className="text-center fade-in-animation"
      sx={{
        my: { xs: 1, sm: 2, },
        mx: 'auto',
        width: { sm: 300, md: '75%' },
        minHeight: 310,
        background: "linear-gradient(to right bottom, #1a237e, #00695c)",
        color: "white",
      }}
    >
      <CardActionArea
        sx={{
            p: 1,
            minHeight: 300,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={advantage.image_src}
            loading="lazy"
            width="90"
            alt=""
            className="advantage-icon"
          />
        </Box>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {advantage.title}
          </Typography>
          <Typography variant="body2" component="div">
            {advantage.content}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default AdvantageCard;
