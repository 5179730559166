import React, { useState } from 'react';
import emailjs from "@emailjs/browser";
import { Alert, Box, Button, Snackbar, TextField } from '@mui/material';

const CareersForm = () => {
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [open, setOpen] = useState(false);
    const [toasterMessage, setToasterMessage] = useState({
        status: '',
        message: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        emailjs
          .send(
            "service_9wk0gmm",
            "template_xmtmotv",
            {
              from_name: name,
              ph_no: mobile,
              email: email,
            },
            "0KZ87jwslyrl0T7ho"
          )
          .then(
            (response) => {
              setOpen(true);
              setToasterMessage({
                status: 'success',
                message: "Your message sent successfully.",
              });
              console.log("SUCCESS!", response.status, response.text);
            },
            (err) => {
              setOpen(true);
              setToasterMessage({
                status: 'error',
                message: "Sorry, There was some issue with sending your message.",
              });
              console.log("FAILED...", err);
            }
          );

    };

    return (

        <Box
            sx={{
                border: '1px solid ',
                borderColor: 'primary.main',
                borderRadius: '16px',
                padding: '1rem'
            }}
        >
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={() => setOpen(false)}
            >
                <Alert severity={toasterMessage?.status}>{toasterMessage.message}</Alert>
            </Snackbar>
            <form onSubmit={handleSubmit}>
                <TextField
                    sx={{
                        margin: {
                            xs: '1rem 0',
                            md: '1rem'
                        },
                        width: {
                            md: '45%',
                            xs: '100%',
                        }
                    }}
                    required
                    id="outlined-required"
                    label="Your Name"
                    className="inputField"
                    name="userName"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    sx={{
                        margin: {
                            xs: '1rem 0',
                            md: '1rem'
                        },
                        width: {
                            md: '45%',
                            xs: '100%',
                        }
                    }}
                    required
                    id="outlined-required"
                    label="Mobile number"
                    className="inputField"
                    name="userName"
                    value={mobile}
                    inputProps={{
                        minLength:"6",
                        pattern:"[^(?=.*?[1-9])[0-9()-]+$",
                    }}
                    onChange={(e) => setMobile(e.target.value)}
                />
                <TextField
                    sx={{
                        margin: {
                            xs: '1rem 0',
                            md: '1rem'
                        },
                        width: {
                            md: '45%',
                            xs: '100%',
                        }
                    }}
                    required
                    id="outlined-required"
                    label="Email"
                    className="inputField"
                    name="userName"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                    type="submit"
                    style={{
                        margin: '1rem'
                    }}
                    className="w-100"
                    size='large'
                    variant="contained">
                    Submit
                </Button>
            </form>
        </Box>
    );
};

export default CareersForm;
