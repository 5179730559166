export const AdvantagesContent = [
    {
        title:'Luxury at Every Level',
        content:`Whether you'd like to build one of our popular floor plans or a custom estate, we'll deliver luxury options and finishes to make your home truly special.`,
        image_src:'https://assets-global.website-files.com/62f58883bbf306e46e87b186/65009feb167fe4b17e392434_icons-02.png',
    },
    {
        title:'Curated Design Approach',
        content:`Arriva home is uniquely crafted for the families who inspire them. Together, we'll build a home unlike any other.`,
        image_src:'https://assets-global.website-files.com/62f58883bbf306e46e87b186/65009febe7920f7a768c1e8d_icons-03.png',
    },
    {
        title:'Award-Winning Service',
        content:`Transparency is key. Our collaborative design team will keep you informed every step of the way.`,
        image_src:'https://assets-global.website-files.com/62f58883bbf306e46e87b186/65009f4b27cbd8d386db0389_icons-04.png',
    },
    {
        title:'A Lifetime of Quality',
        content:`Our homes echo with the promise of durability and excellence, ensuring that the beauty you see today remains for a lifetime.`,
        image_src:'https://assets-global.website-files.com/62f58883bbf306e46e87b186/65009fec0744e3d668acc660_icons-05.png',
    },
];

export const servicesContent = {
    residential : {
        title: 'Residential',
        text: `Our Residential branch is dedicated to crafting neighborhoods we hold in high esteem. Centered on areas with resilient real estate fundamentals, we specialize in plumbing, painting, HVAC maintenance, electrical solutions, and bespoke kitchen cabinetry installations. We enrich the living standards of inhabitants, and the broader community, ensuring enduring advantages.`,
        image: 'https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2FResidential.jpeg?alt=media&token=1da184b0-ed4b-4c48-8dd0-f67aa575d6e6',
    },
    commercial : {
        title: 'Commercial',
        text: `In our Commercial sector, we lead in plumbing, painting, HVAC, electrical systems, and kitchen cabinetry installations. Focused on thriving districts with robust real estate fundamentals, we provide customized, top-tier services to enterprises, elevating commercial environments and fostering prosperity for our clients and the local populace.`,
        image: 'https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Fcommerical.jpeg?alt=media&token=e10cf605-5e19-4d18-8053-4472ad1c1343',
    },
    retail : {
        title: 'Retail',
        text: `Our Retail domain excels in essential offerings encompassing plumbing, painting, HVAC management, electrical services, and customized kitchen cabinetry installations designed specifically for retail venues. We provide tailored solutions that enhance functionality and aesthetics, driving commercial success and enriching the retail landscape for the surroundings.`,
        image: 'https://firebasestorage.googleapis.com/v0/b/arrivahomes-f266e.appspot.com/o/Assets%2Fretial.jpeg?alt=media&token=6b51b973-ccbf-4847-abf0-29738da678c4',
    },
}