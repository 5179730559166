import React from "react";
import { Outlet } from "react-router-dom";

import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";

const Layout = () => {
  return (
    <>
      <Header />
      <div className="app-body">
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default Layout;
